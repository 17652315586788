import axios from "axios";

export const fetchApi = (apiUrl, apiMethod, apiData = null) => {
  return axios({
    data: apiData,
    headers: {
      "Content-Type": "application/json",
      accounttoken: process.env.NEXT_PUBLIC_BTL_ACCOUNT_TOKEN,
      apitoken: process.env.NEXT_PUBLIC_BTL_API_TOKEN,
    },
    method: apiMethod,
    url: apiUrl,
  });
};
