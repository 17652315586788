import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import PropTypes from "prop-types";
import React from "react";

import styles from "./index.module.scss";

const FormattedDescription = ({ richText = null, allowParagraphs = false }) => {
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, next) =>
        allowParagraphs
          ? `<p>${next(node.content)}</p>`
          : `${next(node.content)}`,
    },
  };

  if (richText) {
    return (
      <span
        className={styles.formattedText}
        dangerouslySetInnerHTML={{
          __html: documentToHtmlString(richText, options),
        }}
      />
    );
  }

  return null;
};

FormattedDescription.propTypes = {
  allowParagraphs: PropTypes.bool,
  richText: PropTypes.any,
};

export default FormattedDescription;
