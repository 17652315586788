import PropTypes from "prop-types";
import React from "react";

import LinkArrowBlack from "@/assets/icons/link-arrow-black.svg";
import LinkArrowWhite from "@/assets/icons/link-arrow-white.svg";

import defaultStyles from "./index.module.scss";

const LinkWithArrow = ({
  data,
  styles = {},
  onKeyPressFunc = () => {},
  onClickFunc = () => {},
  isThemeLight,
}) => {
  const componentStyles = { ...defaultStyles, ...styles };
  const { fields } = data;
  const { url, text } = fields;

  return (
    <div className={componentStyles.linkWithArrow}>
      <a
        href={url}
        onClick={onClickFunc}
        onKeyPress={onKeyPressFunc}
        tabIndex={0}
      >
        {text} {isThemeLight ? <LinkArrowBlack /> : <LinkArrowWhite />}
      </a>
    </div>
  );
};

export default LinkWithArrow;

LinkWithArrow.propTypes = {
  data: PropTypes.object,
  isThemeLight: PropTypes.bool,
  onClickFunc: PropTypes.func,
  onKeyPressFunc: PropTypes.func,
  styles: PropTypes.object,
};
