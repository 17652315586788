import { fetchApi } from "./api-service";

const BreakTheLoveAPI = {
  namespace: "breaktheloveapi",

  getAuthToken(payload) {
    return new Promise((resolve, reject) => {
      fetchApi(
        `${process.env.NEXT_PUBLIC_BTL_API_HOST}/api/v1/sso/ott`,
        "POST",
        payload
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
};

export default BreakTheLoveAPI;
